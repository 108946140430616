<div class="row">
  <div class="col-lg-6">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12 images" id="Imagen_Error_401">
          <div>
            <img src="./assets/img/401.png" alt="Error 401" title="Error 401" class="image">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-6">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12 html m-auto py-5 mb-5" id="Texto_gracias_error_401">
          <div class="content">
            <div>
              <p class="h1 text-white">401</p>
            </div>
            <div>
              <p class="h2 text-white mb-3">¡No tienes permisos!</p>
            </div>
            <div>
              <p class="h4 text-white">Parece que no tienes los permisos necesarios para realizar esta acción.</p>
            </div>
          </div>
        </div>
        <div class="button col-lg-12"><a href="/" id="Button_error" class="button-orange mb-5">Regresá
            al Home</a>
        </div>
      </div>
    </div>
  </div>
</div>
