import { JsonObject, JsonProperty } from "json2typescript"

@JsonObject('Titulo')
export class Titulo {
    @JsonProperty('value', String, true)
    value: string = undefined;
    @JsonProperty('props', String, true)
    props: string = undefined;
}

@JsonObject('SubTitulo')
export class SubTitulo {
    @JsonProperty('value', String, true)
    value: string = undefined;
    @JsonProperty('props', String, true)
    props: string = undefined;
}

@JsonObject('Option')
export class Option {
    @JsonProperty('id', String, true)
    id: string = undefined;
    @JsonProperty('value', String, true)
    value: string = undefined;
    @JsonProperty('props', String, true)
    props: string = undefined;
}

@JsonObject('Options')
export class Options {
    @JsonProperty('option', [Option], true)
    option: Option[] = undefined;
}


@JsonObject('DataItem')
export class DataItem {
    @JsonProperty('value', String, true)
    value: string = undefined;
    @JsonProperty('props', String, true)
    props?: string = undefined;
    @JsonProperty('type', String, true)
    type: string = undefined;
    @JsonProperty('options', Options, true)
    options?: Options = undefined;
}

@JsonObject('DataItems')
export class DataItems {
    @JsonProperty('dataItem', [DataItem], true)
    dataItem: DataItem[] = undefined;
}

@JsonObject('ResultItem')
export class ResultItem {
    @JsonProperty('id', String, true)
    id: string = undefined;
    @JsonProperty('value', String, true)
    value: string = undefined;
    @JsonProperty('props', String, true)
    props: string = undefined;
    @JsonProperty('optionId', String, true)
    optionId: string = undefined;
}

@JsonObject('ResultItems')
export class ResultItems {
    @JsonProperty('resultItem', [ResultItem], true)
    resultItem: ResultItem[] = undefined;
}

@JsonObject('PersonalInformation')
export class PersonalInformation {
    @JsonProperty('userPortal', String, true)
    userPortal: string = undefined;
    @JsonProperty('lastName', String, true)
    lastName: string = undefined;
    @JsonProperty('firstName', String, true)
    firstName: string = undefined;
    @JsonProperty('email', String, true)
    email: string = undefined;
    @JsonProperty('fullName', String, true)
    fullName: string = undefined;
    @JsonProperty('props', String, true)
    props: string = undefined;
}

@JsonObject('UserDataFromProcessModel')
export class UserDataFromProcessModel {
    @JsonProperty('titulo', Titulo, true)
    titulo: Titulo = undefined;
    @JsonProperty('subTitulo', SubTitulo, true)
    subTitulo: SubTitulo = undefined;
    @JsonProperty('dataItems', DataItems, true)
    dataItems: DataItems = undefined;
    @JsonProperty('resultItems', ResultItems, true)
    resultItems: ResultItems = undefined;
    @JsonProperty('retries', Number, true)
    retries: number = undefined;
    @JsonProperty('maxRetries', Number, true)
    maxRetries: number = undefined;
    @JsonProperty('personalInformation', PersonalInformation, true)
    personalInformation: PersonalInformation = undefined;
}