import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class HelperService {


    getNavigator() {
        if (
            navigator.userAgent.indexOf("Chrome") !== -1 &&
            navigator.userAgent.indexOf("Chromium") == -1
        ) {
            return "Google Chrome";
        }
        if (
            navigator.userAgent.indexOf("Firefox") !== -1 &&
            navigator.userAgent.indexOf("Seamonkey") == -1
        ) {
            return "Mozilla Firefox";
        }
        if (
            navigator.userAgent.indexOf("Safari") !== -1 &&
            navigator.userAgent.indexOf("Chrome") == -1 &&
            navigator.userAgent.indexOf("Chromium") == -1
        ) {
            return "Safari";
        }
        if (navigator.userAgent.indexOf("Opera") !== -1) {
            return "Opera";
        }
        if (navigator.userAgent.indexOf("Chromium") !== -1) {
            return "Chromium";
        }
        if (/msie\s|trident\/|Edg\//i.test(window.navigator.userAgent)) {
            return "Microsoft Edge";
        }
    }

    getPlatform() {
        let platform = { model: window.navigator.platform, os: "" };
        if (navigator.appVersion.indexOf("Win") != -1) platform.os = "Windows";
        if (navigator.appVersion.indexOf("Mac") != -1) platform.os = "MacOS";
        if (navigator.appVersion.indexOf("X11") != -1) platform.os = "UNIX";
        if (navigator.appVersion.indexOf("Linux") != -1) platform.os = "Linux";
        return platform;
    }
}
