import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthStartPoll } from "src/services/auth.startPoll";

import { Error401Component } from "./error401-component/error401.component";
import { Error404Component } from "./error404-component/error404.component";
import { Error500Component } from "./error500/error500.component";
import { PersonalInformationComponent } from "./personal-information/personal-information.component";
import { PollComponent } from "./poll/poll.component";
import { QuestionsComponent } from "./questions/questions.component";
import { StartComponent } from "./start/start.component";
import { SummaryComponent } from "./summary/summary.component";
import { UserPollsComponent } from "./user-polls/user-polls.component";

const routes: Routes = [
  // {path: 'poll', component: PollComponent, canActivate: [AuthStartPoll]}, // Se puede ingresar siempre
  // {path: 'start', component: StartComponent, canActivate: [AuthStartPoll]},
  { path: "poll/:idPollType/:campaign", component: PollComponent }, // Se puede ingresar siempre
  { path: "start", component: StartComponent }, // Se puede ingresar siempre
  { path: "userPolls", component: UserPollsComponent }, // Se puede ingresar siempre
  {
    path: "personalInformation",
    component: PersonalInformationComponent,
    canActivate: [AuthStartPoll],
  }, // Se puede ingresar SÍ registerPoll == true && startPoll == false
  {
    path: "questions",
    component: QuestionsComponent,
    canActivate: [AuthStartPoll],
  }, // Se puede ingresar SÍ registerPoll == true && startPoll == true && finishedPoll == false
  {
    path: "summary",
    component: SummaryComponent,
    canActivate: [AuthStartPoll],
  }, // Se puede ingresar SÍ registerPoll == true && startPoll == true && finishedPoll == true
  { path: "", redirectTo: "start", pathMatch: "full" },
  { path: "error500", component: Error500Component },
  { path: "error401", component: Error401Component },
  { path: "**", component: Error404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
