import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DataService } from './data.service';

import { PollsService } from './polls.service';


@Injectable({
  providedIn: 'root',
})
export class AuthStartPoll implements CanActivate {
  constructor(private router: Router,
    private dataService: DataService,
    private service: PollsService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    // poll // Se puede ingresar siempre
    // start // Se puede ingresar siempre
    // personalInformation  // Se puede ingresar SÍ registerPoll == true && startPoll == false
    // questions  // Se puede ingresar SÍ registerPoll == true && startPoll == true && finishedPoll == false
    // summary  // Se puede ingresar SÍ registerPoll == true && startPoll == true && finishedPoll == true
    const route = next.routeConfig.path;
    let result;
    switch (route) {
      case "personalInformation":
        const idTestVocacionalV2 = parseInt(this.service.getIdTestVocacionalV2());
        result = this.dataService.idPollType == idTestVocacionalV2 ? this.service.registerPoll : (this.service.registerPoll && !this.service.startPoll);
        break;
      case "questions":
        result = this.service.registerPoll && this.service.startPoll && !this.service.finishPoll;
        break;
      case "summary":
        result = this.service.registerPoll && this.service.startPoll && this.service.finishPoll;
        break;
      default:
        result = false;
        break;
    }
    if (!result) {
      this.router.navigate(['/start'], {});
    }
    return result;
    // if (route == ) { return true; }
  }
}
