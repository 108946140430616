import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JsonConvert, ValueCheckingMode } from "json2typescript";
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppError } from 'src/models/error';

@Injectable({
  providedIn: "root"
})
export class BaseApiService {
  protected jsonConvert: JsonConvert = new JsonConvert();
  protected baseUrl = environment.api_url;
  protected testDomain = environment.testDomain;
  protected ddjjDomain = environment.ddjjDomain;
  protected listOptions = environment.listOptions;
  protected yesNoOptions = environment.yesNoOptions;
  protected textOptions = environment.textOptions;
  protected checkboxOptions = environment.checkboxOptions;
  protected autocompleteOptions = environment.autocompleteOptions;
  protected labelOptions = environment.labelOptions;
  protected customRangeOption = environment.customRangeOption;
  protected rangeValueOption = environment.rangeValueOption;
  protected ues21ApiUrl = environment.ues21Api_url;
  protected clientId = environment.client_id;
  protected idTestVocacional = environment.idTestVocacional;
  protected idTestVocacionalV2 = environment.idTestVocacionalV2;
  protected defaultIdTest = environment.defaultIdTest;
  protected idEncuestaCovidAlumno = environment.idEncuestaCovidAlumno;
  protected idEncuestaCovidDocente = environment.idEncuestaCovidDocente;
  protected idEncuestaCovidExterno = environment.idEncuestaCovidExterno;
  protected showCaptcha = environment.showCaptcha;
  protected useDefaultPoll = environment.useDefaultPoll;
  protected oauthDataMode = environment.oauthDataMode;
  protected version = environment.version;
  protected backgroundColor = environment.backgroundColor;
  protected production = environment.production;

  constructor(protected http: HttpClient) {
    this.jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
  }

  _get<T>(
    url: string,
    type?: new () => T,
    isArray?: boolean,
    headers?: HttpHeaders
  ) {
    if (!headers) {
      headers = new HttpHeaders()
        .set("Content-Type", "application/json")
        .set("Authorization", "Basic " + this.getToken());
    }
    if (!headers.has("Authorization")) {
      headers = headers.append("Authorization", "Basic " + this.getToken());
    }
    if (!headers.has("Content-Type")) {
      headers = headers.append("Content-Type", "application/json");
    }

    return this.http
      .get(url, {
        headers: headers
      })
      .pipe(
        map(res =>
          type != null
            ? isArray
              ? this.jsonConvert.deserializeArray(<any[]>res, type)
              : this.jsonConvert.deserialize(<any>res, type)
            : res
        ),
        catchError(this.handleError)
      );
  }

  _post<T>(
    url: string,
    body: any,
    options: any,
    type?: new () => T,
    isArray?: boolean
  ) {
    if (!options) {
      options = {
        headers: new HttpHeaders()
          .set("Content-Type", "application/json")
          .set("Authorization", "Basic " + this.getToken())
      };
    }

    if (!options.headers) {
      options.headers = new HttpHeaders()
        .set("Content-Type", "application/json")
        .set("Authorization", "Basic " + this.getToken());
    }
    if (!options.headers.has("Authorization")) {
      options.headers = options.headers.append(
        "Authorization",
        "Basic " + this.getToken()
      );
    }
    if (!options.headers.has("Content-Type")) {
      options.headers = options.headers.append(
        "Content-Type",
        "application/json"
      );
    }

    return this.http.post(url, JSON.stringify(body), options).pipe(
      map((res: any) =>
        type != null
          ? isArray
            ? this.jsonConvert.deserializeArray(res, type)
            : this.jsonConvert.deserialize(res, type)
          : res
      ),
      catchError(this.handleError)
    );
  }

  _put<T>(
    url: string,
    body: any,
    options: any,
    type?: new () => T,
    isArray?: boolean
  ) {
    if (!options) {
      options = {
        headers: new HttpHeaders()
          .set("Content-Type", "application/json")
          .set("Authorization", "Basic " + this.getToken())
      };
    }

    if (!options.headers) {
      options.headers = new HttpHeaders()
        .set("Content-Type", "application/json")
        .set("Authorization", "Basic " + this.getToken());
    }
    if (!options.headers.has("Authorization")) {
      options.headers = options.headers.append(
        "Authorization",
        "Basic " + this.getToken()
      );
    }
    if (!options.headers.has("Content-Type")) {
      options.headers = options.headers.append(
        "Content-Type",
        "application/json"
      );
    }

    return this.http.put(url, JSON.stringify(body), options).pipe(
      map((res: any) =>
        type != null
          ? isArray
            ? this.jsonConvert.deserializeArray(res, type)
            : this.jsonConvert.deserialize(res, type)
          : res
      ),
      catchError(this.handleError)
    );
  }

  _patch<T>(
    url: string,
    body: any,
    options: any,
    type?: new () => T,
    isArray?: boolean
  ) {
    if (!options) {
      options = {
        headers: new HttpHeaders()
          .set("Content-Type", "application/json")
          .set("Authorization", "Basic " + this.getToken())
      };
    }

    if (!options.headers) {
      options.headers = new HttpHeaders()
        .set("Content-Type", "application/json")
        .set("Authorization", "Basic " + this.getToken());
    }
    if (!options.headers.has("Authorization")) {
      options.headers = options.headers.append(
        "Authorization",
        "Basic " + this.getToken()
      );
    }
    if (!options.headers.has("Content-Type")) {
      options.headers = options.headers.append(
        "Content-Type",
        "application/json"
      );
    }

    return this.http.patch(url, JSON.stringify(body), options).pipe(
      map((res: any) =>
        type != null
          ? isArray
            ? this.jsonConvert.deserializeArray(res, type)
            : this.jsonConvert.deserialize(res, type)
          : res
      ),
      catchError(this.handleError)
    );
  }

  _delete<T>(
    url: string,
    id: any,
    options: any,
    type?: new () => T,
    isArray?: boolean
  ) {
    if (!options) {
      options = {
        headers: new HttpHeaders()
          .set("Content-Type", "application/json")
          .set("Authorization", "Basic " + this.getToken())
      };
    }

    if (!options.headers) {
      options.headers = new HttpHeaders()
        .set("Content-Type", "application/json")
        .set("Authorization", "Basic " + this.getToken());
    }
    if (!options.headers.has("Authorization")) {
      options.headers = options.headers.append(
        "Authorization",
        "Basic " + this.getToken()
      );
    }
    if (!options.headers.has("Content-Type")) {
      options.headers = options.headers.append(
        "Content-Type",
        "application/json"
      );
    }

    if (id != null) {
      url = url + "/" + id;
    }

    return this.http.delete(url, options).pipe(
      map((res: any) =>
        type != null
          ? isArray
            ? this.jsonConvert.deserializeArray(res, type)
            : this.jsonConvert.deserialize(res, type)
          : res
      ),
      catchError(this.handleError)
    );
  }

  private handleError(error: Response) {
    if (error.status === 401) {
      console.error(error);
      window.location.href = '/error401'
    };
    if (error.status === 404) {
      console.error(error);
      window.location.href = '/error404'
    };
    if (error.status === 500) {
      console.error(error);
      window.location.href = '/error500'
    };
    return throwError(new AppError(error));
  }

  protected getToken(): string {
    if (localStorage.getItem('_bizuit_token')) {
      return localStorage.getItem('_bizuit_token');
    } else return null;
  }
}
