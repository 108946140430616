import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('PollVars')
export class PollVars {
  @JsonProperty('authType', String, true)
  authType: string = undefined;
  @JsonProperty('headerLogo', String, true)
  headerLogo: string = undefined;
  @JsonProperty('footerLogo', String, true)
  footerLogo: string = undefined;
  @JsonProperty('idTemplate', String, true)
  idTemplate: string = undefined;
  @JsonProperty('hasExtraUserData', Boolean, true)
  hasExtraUserData: boolean = undefined;
  @JsonProperty('activePoll', Boolean, true)
  activePoll: boolean = undefined;
  @JsonProperty('activeCampaign', Boolean, true)
  activeCampaign: boolean = undefined;
}
