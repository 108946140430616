import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { PollsService } from 'src/services/polls.service';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  idEncuestaCovidAlumno: any;
  idEncuestaCovidDocente: any;
  idEncuestaCovidExterno: any;
  captchaResponse: string;
  resolvedCaptcha: boolean;
  showCaptcha: boolean = true;

  constructor(private router: Router, private service: PollsService,
    private _snackBar: MatSnackBar) {
    }

  ngOnInit(): void {
    console.warn('VERSION APP: ' + this.service.getVersion());
    localStorage.removeItem("token");
    this.idEncuestaCovidAlumno = this.service.getIdEncuestaCovidAlumno();
    this.idEncuestaCovidDocente = this.service.getIdEncuestaCovidDocente();
    this.idEncuestaCovidExterno = this.service.getIdEncuestaCovidExterno();
    this.showCaptcha = this.service.getShowCaptcha() == "true" ? true : false;
    const domain = window.location.hostname.split(".")[0];
    if (domain != this.service.getDDJJDomain()) {
      if (domain == this.service.getTestDomain() && this.service.getUseDefaultPoll() == "true") {
        this.start(this.service.getDefaultIdTest(), 109)
      } else {
        this.router.navigate(['/error404']);
      }
    }
  }

  // finish() {
  //   window.top.postMessage('finishReminder','*');
  // }

  // hide() {
  //   window.top.postMessage('hideCloseReminder','*');
  // }

  // close() {
  //   window.top.postMessage('closeReminder','*');
  // }

  start(idPollType?, campaign?) {
    this.router.navigate(['/poll/' + idPollType + "/" + campaign]);
  }

  resolved(captchaResponse: string) {
    this.captchaResponse = captchaResponse;
    if (captchaResponse != undefined && captchaResponse != null && captchaResponse.length > 0) {
      this.blockUI.start();
      this.service.validateToken("Google-Token " + captchaResponse).subscribe((result) => {
        this.blockUI.stop();
        localStorage.setItem("token", captchaResponse);
        this.resolvedCaptcha = true;
      }, (error) => {
        this.blockUI.stop();
        console.error(error);
        this._snackBar.open('Ocurrió un error al validar el Captcha, por favor, intente nuevamente', 'Aceptar', {
          duration: 8000,
          horizontalPosition: "center",
          verticalPosition: "top"
        });
        this.resolvedCaptcha = false;
      })
    } else {
      this.resolvedCaptcha = false;
    }
  }

}
