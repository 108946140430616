import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { PollsModel } from "src/models/polls.model";
import { PollVars } from "src/models/pollVars.model";
import { ValidateUserResult } from "src/models/validate-user-result.model";
import { DataService } from "src/services/data.service";
import { PollsService } from "src/services/polls.service";

@Component({
  selector: "app-poll",
  templateUrl: "./poll.component.html",
  styleUrls: ["./poll.component.scss"],
})
export class PollComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  idPollType: any;
  campaign: any;
  ues21ApiUrl: any;
  clientId: any;
  validatePollMessage: string;

  constructor(
    private service: PollsService,
    private router: Router,
    private dataService: DataService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.idPollType = this.route.snapshot.paramMap.get("idPollType");
    this.campaign = this.route.snapshot.paramMap.get("campaign");
    this.ues21ApiUrl = this.service.getUes21ApiUrl();
    this.clientId = this.service.getClientId();
    this.route.queryParams.subscribe((params) => {
      if (params) {
        if (params.code) {      
          const hasExtraUserData = localStorage.getItem('hasExtraUserData').toLowerCase() == "true";
          if (hasExtraUserData) {
            this.validateToken("UES21-Code " + params.code, () => {
              this.router.navigate(["/userPolls"]);
            })
          } else {
            this.registerPoll(params.code);
          }
        } else {
          this.getAuthType();
        }
      }
    });
  }

  getAuthType() {
    this.blockUI.start();
    this.service.getAuthType(this.idPollType, this.campaign).subscribe(
      (result: PollVars) => {
        this.blockUI.stop();
        if (!result.activePoll) {
          this.validatePollMessage = "La encuesta ya no esta disponible";
        } else {
          if (!result.activeCampaign) {
            this.validatePollMessage = "La campaña ya no esta disponible";
          } else {
            localStorage.setItem("HeaderLogoUrl", result.headerLogo);
            localStorage.setItem("FooterLogoUrl", result.footerLogo);
            localStorage.setItem("idPollType", this.idPollType);
            localStorage.setItem("idCampaign", this.campaign);
            localStorage.setItem("hasExtraUserData", result.hasExtraUserData.toString());
            switch (result.authType.toLowerCase()) {
              case "oauth":
                localStorage.setItem("authType", 'oauth');
                window.open(
                  this.ues21ApiUrl +
                  "authorize?client_id=" +
                  this.clientId +
                  "&response_type=code&redirect_uri=" +
                  window.location.href.replace("start", "poll"),
                  "_self"
                );
                break;
              case "oauthsis":
                localStorage.setItem("authType", 'oauthsis');
                window.open(
                  this.ues21ApiUrl +
                  "authorize?client_id=" +
                  this.clientId +
                  "&response_type=code&redirect_uri=" +
                  window.location.href.replace("start", "poll"),
                  "_self"
                );
                break;
              default:
                localStorage.removeItem("authType");
                this.registerPoll();
                break;
            }
          }
        }
      },
      (error) => {
        this.blockUI.stop();
        console.error(error);
      }
    );
  }

  registerPoll(code?) {
    this.blockUI.start();
    this.service.getRegisterPoll(this.idPollType, this.campaign).subscribe(
      (result: PollsModel) => {
        this.dataService.registerPoll = result;
        if (code) {
          this.dataService.code = code;
        }
        this.blockUI.stop();
        this.dataService.role =
          this.idPollType == this.service.getIdEncuestaCovidDocente()
            ? "CODOCENTE"
            : "EXTERNO";
        this.service.registerPoll = true;
        if (!result.hasPersonalQuestions) {
          this.router.navigate(["/personalInformation"]);
        } else {
          this.validateToken("Basic " + this.newGUID(), () => {
            this.dataService.registerPoll.listOptions =
              this.service.getListOptions();
            this.dataService.registerPoll.checkboxOptions =
              this.service.getCheckboxOptions();
            this.dataService.registerPoll.customRangeOption =
              this.service.getCustomRangeOption();
            this.blockUI.start("Cargando Preguntas");
            this.service.updatePoll(this.dataService.registerPoll).subscribe(
              (result) => {
                this.dataService.registerPoll = this.dataService.registerPoll;
                this.dataService.questionsData = result;
                this.service.startPoll = true;
                this.router.navigate(["/questions"]);
                this.blockUI.stop();
              },
              (error) => {
                this.blockUI.stop();
                console.error(error);
              }
            );
          });
        }
      },
      (error) => {
        this.blockUI.stop();
        console.error(error);
      }
    );
  }

  validateUserPortal(callback) {
    this.blockUI.start();
    this.service.validateUserPortal(this.idPollType).subscribe((res: ValidateUserResult) => {
      this.blockUI.stop();
      if (parseInt(res.results.status) == 200) {
        callback();
      }
      else {
        this.validatePollMessage = res.results.message;
      }
    }, (error) => {
      console.error(error);
    })
  }

  newGUID() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  validateToken(token, callback) {
    this.blockUI.start();
    this.service.validateToken(token).subscribe(
      (result) => {
        localStorage.setItem("token", result);
        this.blockUI.stop();
        callback()
      },
      (error) => {
        this.blockUI.stop();
        console.error(error);
      }
    );
  }
}
