<div class="container" [ngClass]="dataService.idPollType == idTestVocacionalV2 && 'v2Style'">
  <!-- <div class="logoContainer">
    <img src="https://21.edu.ar/sites/default/files/logos/logo-siglo21-25a.png">
  </div> -->
  <div style="padding-top: 50px;">
    <mat-card>
      <mat-card-content class="textContainer">
        <div class="row">
          <div class="col-md-12" [ngClass]="dataService.idPollType == idTestVocacionalV2 && 'v2Text'">
            <h3>
              <div [innerHTML]="message | safeHtml"></div>
            </h3>
          </div>
        </div>
      </mat-card-content>
      <div class="buttonContainer" *ngIf="resultado">
        <div class="row">
          <div class="col-md-12">
            <button mat-raised-button color="primary" (click)="openDoc()">Ver Resultados</button>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</div>
