import { Injectable } from '@angular/core';
import { RegisterPollsModel } from 'src/models/registerPoll.model';
import { PollStructure } from 'src/models/pollStructure.model';
import { FinishedPoll } from 'src/models/finishedPoll.model';
import { PollsModel } from 'src/models/polls.model';
import { Observable, Subject } from 'rxjs';
import { PersonalInformation } from 'src/models/user-personal-information.model';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private headerLogo = new Subject<any>();
  private _footerLogo = new Subject<any>();
  registerPoll: PollsModel = new PollsModel();
  questionsData: PollStructure;
  finishedPoll: FinishedPoll = new FinishedPoll();
  code: string = null;
  role: string = null;
  idTemplate: number = null;
  idPollType: number = null;

  constructor() { }


  setHeaderLogoUrl(status: string) {
    this.headerLogo.next(status);
  }

  getHeaderLogoUrl(): Observable<any> {
    return this.headerLogo.asObservable();
  }

  setFooterLogo(value: string) {
    this._footerLogo.next(value);
  }

  getFooterLogo(): Observable<any> {
    return this._footerLogo.asObservable();
  }
}
