import { JsonObject, JsonProperty } from "json2typescript";
import { PersonalInformation } from "./user-personal-information.model";

@JsonObject("Status")
export class Status {
    @JsonProperty("status", String, true)
    status: string = null;
    @JsonProperty("message", String, true)
    message: string = null;
}

@JsonObject("ValidateUserResult")
export class ValidateUserResult {
    @JsonProperty("results", Status, true)
    results: Status = null;
}