<block-ui>
  <div class="container" *ngIf="authType != 'oauthsis'">
    <div class="formContainer">
      <mat-card>
        <mat-card-content>
          <div class="row" *ngIf="dataService.idPollType != idTestVocacionalV2 && (code || token || !showCaptcha)">
            <div class="col-md-12">
              <mat-form-field class="form-full-width">
                <mat-label>Rol</mat-label>
                <input matInput type="text" [(ngModel)]="poll.rol" readonly>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-6 col-xs-12"
              *ngIf="dataService.idPollType == idTestVocacionalV2 ? !service.finishPoll : true">
              <mat-form-field class="form-full-width">
                <mat-label>Nombre</mat-label>
                <input matInput type="text" (keyup)="invalidName = validate($event.target.value, '^([ a-zA-Z\'])+$')"
                  placeholder="Ej. Julieta" [(ngModel)]="poll.name" required [readonly]="code">
              </mat-form-field>
              <mat-error *ngIf="invalidName">Solo se permiten letras</mat-error>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12"
              *ngIf="dataService.idPollType == idTestVocacionalV2 ? service.finishPoll : true">
              <mat-form-field class="form-full-width">
                <mat-label>Apellido</mat-label>
                <input matInput type="text"
                  (keyup)="invalidLastName = validate($event.target.value, '^([ a-zA-Z\'])+$')"
                  placeholder="Ej. Lopez" [(ngModel)]="poll.lastName" required [readonly]="code">
              </mat-form-field>
              <mat-error *ngIf="invalidLastName">Solo se permiten letras</mat-error>
            </div>
          </div>
          <!-- <div class="row" *ngIf="!code && !token && showCaptcha">
            <div class="col-md-12">
              <mat-form-field class="form-full-width">
                <mat-label>Edad</mat-label>
                <input matInput type="number" min="10" max="100"
                  (change)="invalidAge = validate($event.target.value, '^([0-9]{2,3})$')"
                  (keyup)="invalidAge = validate($event.target.value, '^([0-9]{2,3})$')" placeholder="Ej. 18"
                  [(ngModel)]="poll.age">
              </mat-form-field>
              <mat-error *ngIf="invalidAge">Ingrese solo números de hasta tres dígitos</mat-error>
            </div>
          </div> -->
          <div class="row" *ngIf="dataService.idPollType != idTestVocacionalV2">
            <!-- <div class="col-md-6 col-sm-6 col-xs-12">
              <mat-form-field class="form-full-width optionList">
                <mat-label>Tipo de Documento</mat-label>
                <mat-select [(ngModel)]="poll.documentType">
                    <mat-option value="1">DNI</mat-option>
                    <mat-option value="2">LE</mat-option>
                    <mat-option value="3">LC</mat-option>
                    <mat-option value="4">PASAPORTE</mat-option>
                    <mat-option value="5">CEDULA</mat-option>
                    <mat-option value="6">CUIT</mat-option>
                </mat-select>
              </mat-form-field>
            </div> -->
            <div class="col-md-6 col-sm-6 col-xs-12">
              <mat-form-field class="form-full-width">
                <mat-label>DNI</mat-label>
                <input type="number"
                  (change)="invalidDocument = validate($event.target.value, '^\\d{8}(?:[-\\s]\\d{4})?$')"
                  (keyup)="invalidDocument = validate($event.target.value, '^\\d{8}(?:[-\\s]\\d{4})?$')" matInput
                  placeholder="Ej. 11111111" [(ngModel)]="poll.dni" [readonly]="code"
                  [required]="code || token || !showCaptcha">
              </mat-form-field>
              <mat-error *ngIf="invalidDocument">Por favor compruebe su numero de documento</mat-error>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12">
              <mat-form-field class="form-full-width">
                <mat-label>Fecha de Nacimiento</mat-label>
                <input matInput [matDatepicker]="picker" [max]="currentDate" [(ngModel)]="poll.birthDate">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
          <div class="row" *ngIf="dataService.idPollType == idTestVocacionalV2 ? service.finishPoll : true">
            <div class="col-md-6 col-sm-6 col-xs-12">
              <mat-form-field class="form-full-width">
                <mat-label>Correo Electrónico</mat-label>
                <input matInput [formControl]="email" type="email" placeholder="Ej. ejemplo@mail.com"
                  [(ngModel)]="poll.mail" required>
                <mat-error *ngIf="email.invalid">Ingrese un email valido</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12">
              <mat-form-field class="form-full-width">
                <mat-label>Teléfono Movil</mat-label>
                <!-- (change)="invalidMobilePhone = $event.target.value.length ? validate($event.target.value, '^[1-9]\\d{9}?$') : false"
                (keyup)="invalidMobilePhone = $event.target.value.length ? validate($event.target.value, '^[1-9]\\d{9}?$') : false" -->
                <input type="tel" matInput
                  (change)="invalidMobilePhone = $event.target.value.length ? validate($event.target.value, '^[0-9]+$') : false"
                  (keyup)="invalidMobilePhone = $event.target.value.length ? validate($event.target.value, '^[0-9]+$') : false"
                  placeholder="1155512346" [(ngModel)]="poll.mobilePhone">
              </mat-form-field>
              <mat-error *ngIf="invalidMobilePhone">Por favor ingrese solo números</mat-error>
            </div>
          </div>
          <!-- <div class="row">
            <div class="col-md-6 col-sm-6 col-xs-12">
              <mat-form-field class="form-full-width">
                <mat-label>Teléfono Fijo</mat-label>
                (change)="invalidPhone = $event.target.value.length ? validate($event.target.value, '^[1-9]\\d{9}?$') : false"
                (keyup)="invalidPhone = $event.target.value.length ? validate($event.target.value, '^[1-9]\\d{9}?$') : false"
                <input type="tel" matInput
                  (change)="invalidPhone = $event.target.value.length ? validate($event.target.value, '^[0-9]+$') : false"
                  (keyup)="invalidPhone = $event.target.value.length ? validate($event.target.value, '^[0-9]+$') : false"
                  placeholder="1155512346" [(ngModel)]="poll.phone">
              </mat-form-field>
              <mat-error *ngIf="invalidPhone">Por favor ingrese solo números</mat-error>
            </div>
          </div> -->
          <div class="row"
            *ngIf="dataService.idPollType == idTestVocacionalV2 ? service.finishPoll : (!code && !token && showCaptcha)">
            <div class="col-md-6 col-sm-6 col-xs-12">
              <mat-form-field class="form-full-width">
                <mat-label>Provincia</mat-label>
                <mat-select (selectionChange)="getCities($event.value)"
                  [required]="dataService.idPollType == idTestVocacionalV2">
                  <mat-option *ngFor="let province of provinces" [value]="province">
                    {{province.nombre}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12">
              <mat-form-field class="form-full-width">
                <mat-label>Localidad</mat-label>
                <!-- <mat-select
                  [disabled]="poll.province == null || poll.province == undefined || poll.province.length <= 0"
                  [(ngModel)]="poll.city">
                  <mat-option *ngFor="let city of cities" [value]="city.nombre">
                    {{city.nombre}}
                  </mat-option>
                </mat-select> -->
                <input type="text" placeholder="Ej. Córdoba" aria-label="Number" matInput [formControl]="myControl"
                  [matAutocomplete]="auto"
                  [(ngModel)]="poll.city"
                  [readonly]="poll.province == null || poll.province == undefined || poll.province.length <= 0">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                  <mat-option *ngFor="let city of filteredOptions | async" [value]="city.nombre">
                    {{city.nombre}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>
        </mat-card-content>
        <!-- Production / QA -->
        <re-captcha (resolved)="resolved($event)" siteKey="6LcHYcMZAAAAAFYNbLW0wKtuS3V35Rg_myq3BxPq"
          *ngIf="!code && !token && showCaptcha && (dataService.idPollType == idTestVocacionalV2 ? !service.finishPoll : true)">
        </re-captcha>
        <!-- Dev -->
        <!-- <re-captcha (resolved)="resolved($event)" siteKey="6LfZMrUZAAAAANeg8-LQyn2qD2gQr2e_uDldItSt"
            *ngIf="!code && !token && showCaptcha && (dataService.idPollType == idTestVocacionalV2 ? !service.finishPoll : true)">
          </re-captcha> -->
        <mat-card-actions align=end style="text-align: center;">
          <button mat-raised-button color="primary" (click)="startQuestions()" [disabled]="
            (dataService.idPollType == idTestVocacionalV2 ?
            (!service.finishPoll ?
            (!poll.name || poll.name.length <= 0) :
            (!poll.lastName || poll.lastName.length <= 0)
            || (!poll.mail || email.invalid)
            || (!poll.province || poll.province.length <= 0)) :
            ((!poll.name || poll.name.length <= 0)
            || (!poll.lastName || poll.lastName.length <= 0)
            || (!poll.mail || email.invalid)
            || ( code || token || !showCaptcha ? poll.dni == null || poll.dni.length <= 0 : false)
            || invalidName
            || invalidAge
            || invalidDocument
            || invalidPhone))
            || !resolvedCaptcha">{{ dataService.idPollType == idTestVocacionalV2 && service.finishPoll ? 'Finalizar' : 'Siguiente' }}</button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</block-ui>
