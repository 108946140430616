import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('FinishedPoll')
export class FinishedPoll {
  @JsonProperty('instanceId', String, true)
  instanceId: string = undefined;
  @JsonProperty('estado', String, true)
  estado: string = undefined;
  @JsonProperty('resultado', String, true)
  resultado: string = undefined;
  @JsonProperty('mensaje', String, true)
  mensaje: string = undefined;
}
