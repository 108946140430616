<block-ui>
    <div class="container" *ngIf="validatePollMessage && validatePollMessage.length > 0">
        <!-- <div class="logoContainer">
        <img src="https://21.edu.ar/sites/default/files/logos/logo-siglo21-25a.png">
      </div> -->
        <div style="padding-top: 50px;">
            <mat-card>
                <mat-card-content class="textContainer">
                    <div class="row">
                        <div class="col-md-12">
                            <h2>
                                <div [innerHTML]="validatePollMessage | safeHtml"></div>
                            </h2>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</block-ui>