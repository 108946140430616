import { Component, HostListener } from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { Subscription } from "rxjs";
import { DataService } from "src/services/data.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  headerLogo = "";
  footerLogo = "";
  title = "pollPage";

  constructor(private dataService: DataService) {
    setTimeout(() => {
      if (
        document.getElementsByClassName("btn-otro-momento z-button").length >= 1
      ) {
        (
          document.getElementsByClassName(
            "btn-otro-momento z-button"
          )[0] as HTMLElement
        ).style.display = "none";
      }
    }, 500);
    this.dataService.getHeaderLogoUrl().subscribe((result) => {
      if (
        result &&
        result.length > 0 &&
        result != "undefined" &&
        result != "null"
      ) {
        this.headerLogo = result;
      }
    });
    this.dataService.getFooterLogo().subscribe((result) => {
      const aux = parseInt(result);
      if (
        result &&
        result.length > 0 &&
        result != "undefined" &&
        result != "null" &&
        typeof aux == "string"
      ) {
        this.footerLogo = result;
      }
    });
  }
  // @HostListener("window:beforeunload", ["$event"])
  // beforeUnloadHander() {
  //   // or directly false
  //   alert("pepe");
  // }
}
