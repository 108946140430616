import {
  JsonConvert,
  JsonConverter,
  JsonCustomConvert,
  JsonObject,
  JsonProperty,
  OperationMode,
  ValueCheckingMode,
} from 'json2typescript';


// JSONCONVERT INSTANCE
let jsonConvert = new JsonConvert();
jsonConvert.operationMode = OperationMode.ENABLE;
jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
jsonConvert.ignorePrimitiveChecks = false;

@JsonConverter
class DateConverter implements JsonCustomConvert<Date> {
  serialize(date: Date): any {
    return (
      date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    );
  }
  deserialize(date: any): Date {
    return new Date(date);
  }
}


@JsonObject('PollsModel')
export class PollsModel {
  @JsonProperty('id', Number)
  id: number = undefined;
  @JsonProperty('timeStamp', DateConverter)
  timeStamp: Date = undefined;
  @JsonProperty('birthDate', Date, true)
  birthDate?: Date = undefined;
  @JsonProperty('mail', String, true)
  mail?: string = undefined;
  @JsonProperty('name', String, true)
  name?: string = undefined;
  @JsonProperty('lastName', String, true)
  lastName?: string = undefined;
  @JsonProperty('phone', String, true)
  phone?: string = undefined;
  @JsonProperty('mobilePhone', String, true)
  mobilePhone?: string = undefined;
  @JsonProperty('documentType', String, true)
  documentType?: string = "1";
  @JsonProperty('dni', String, true)
  dni?: string = undefined;
  @JsonProperty('province', String, true)
  province?: string = undefined;
  @JsonProperty('city', String, true)
  city?: string = undefined;
  @JsonProperty('age', DateConverter, true)
  age?: Date = undefined;
  @JsonProperty('explorer', String, true)
  explorer?: string = undefined;
  @JsonProperty('os', String, true)
  os?: string = undefined;
  @JsonProperty('model', String, true)
  model?: string = undefined;
  @JsonProperty('ip', String, true)
  ip?: string = undefined;
  @JsonProperty('endStamp', DateConverter, true)
  endStamp?: Date = undefined;
  @JsonProperty('data', String, true)
  data?: string = undefined;
  @JsonProperty('idPollType', Number)
  idPollType: number = 106;
  @JsonProperty('idCampain', Number)
  idCampain: number = 106;
  @JsonProperty('instanceId', String, true)
  instanceId?: string = undefined;
  @JsonProperty('idPreviousPoll', Number, true)
  idPreviousPoll?: number = undefined;
  @JsonProperty('status', Number, true)
  status?: number = undefined; // 0 En progreso , 1 Continuando por otro, 2 Finalizado
  @JsonProperty('listOptions', Number, true)
  listOptions?: number = undefined;
  @JsonProperty('checkboxOptions', Number, true)
  checkboxOptions?: number = undefined;
  @JsonProperty('customRangeOption', Number, true)
  customRangeOption?: number = undefined;
  @JsonProperty('rol', String, true)
  rol?: string = undefined;
  @JsonProperty('userName', String, true)
  userName?: string = undefined;
  @JsonProperty('hasPersonalQuestions', Boolean, true)
  hasPersonalQuestions?: boolean = false;
}
