import { Component, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { Router } from "@angular/router";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { PollsModel } from "src/models/polls.model";
import { UserDataModel } from "src/models/userDataModel.model";
import { DataService } from "src/services/data.service";
import { HelperService } from "src/services/helper.service";
import { PollsService } from "src/services/polls.service";

export const MY_FORMATS = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMM YYYY",
  },
};

@Component({
  selector: "app-personal-information",
  templateUrl: "./personal-information.component.html",
  styleUrls: ["./personal-information.component.scss"],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class PersonalInformationComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  resolvedCaptcha = false;

  email = new FormControl("", [Validators.required, Validators.email]);
  poll: PollsModel = new PollsModel();
  code: string = null;
  token: string = null;
  provinces: Array<{ id: number; nombre: string }> = [];
  cities: Array<{ id: number; nombre: string; idProvincia: number }> = [];
  myControl = new FormControl();
  filteredOptions: Observable<
    Array<{ id: number; nombre: string; idProvincia: number }>
  >;
  captchaResponse: string;
  invalidName = null;
  invalidLastName = null;
  invalidAge = null;
  invalidPhone = null;
  invalidMobilePhone = null;
  invalidDocument = null;
  showCaptcha: boolean = true;
  currentDate = new Date();
  idPollType: number = 0;
  idTestVocacionalV2: number = 0;
  authType: string;
  // isProduction: any = false;

  constructor(
    private router: Router,
    public service: PollsService,
    public dataService: DataService,
    private helperService: HelperService
  ) {
    // this.isProduction = this.service.isProduction();
    this.authType = localStorage.getItem("authType");
    this.idPollType = parseInt(localStorage.getItem("idPollType"));
    if (!this.dataService.idPollType || this.dataService.idPollType <= 0) {
      this.dataService.idPollType = this.idPollType;
    }
    // localStorage.removeItem("idPollType");
    this.idTestVocacionalV2 = parseInt(this.service.getIdTestVocacionalV2());

    const logoHeader = localStorage.getItem("HeaderLogoUrl");
    this.dataService.setHeaderLogoUrl(logoHeader);
    localStorage.removeItem("HeaderLogoUrl");
    const footerHeader = localStorage.getItem("FooterLogoUrl");
    this.dataService.setFooterLogo(footerHeader);
    localStorage.removeItem("FooterLogoUrl");
  }

  ngOnInit(): void {
    this.poll = this.dataService.registerPoll;
    this.code = this.dataService.code;
    this.showCaptcha = this.service.getShowCaptcha() == "true" ? true : false;
    const domain = window.location.hostname.split(".")[0];
    if (domain == this.service.getTestDomain() && this.authType != 'oauthsis') {
      this.getProvinces();
    }
    if (this.code) {
      this.getUserData();
    } else {
      this.poll.rol = this.dataService.role;
      this.token = localStorage.getItem("token");
      if (this.token) {
        this.resolvedCaptcha = true;
      } else {
        if (!this.showCaptcha) {
          this.validateToken("Basic " + this.newGUID());
        }
      }
    }
    
    this.poll.explorer = this.helperService.getNavigator();
    const platform = this.helperService.getPlatform();
    this.poll.model = platform.model;
    this.poll.os = platform.os;

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value))
    );
    if (this.dataService.idPollType == this.idTestVocacionalV2) {
      this.setBackgroundAndShowMessage();
      var footer = document.getElementById("footer");
      footer.className = "v2Style";
      var header = document.getElementById("header");
      header.className = "v2Style";
    }
  }

  setBackgroundAndShowMessage() {
    let mainContainer = document.getElementById("mainContainer");
    if (mainContainer) {
      mainContainer.style.background =
        "url(./../../assets/img/resources/backgrounds/" +
        (this.dataService.idPollType == this.idTestVocacionalV2 &&
        !this.service.finishPoll
          ? "generico3.jpg"
          : "naturalista.jpg") +
        ") no-repeat center center fixed";
      mainContainer.style.backgroundSize = "cover";
    }
  }

  newGUID() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  getUserData() {
    this.blockUI.start("Cargando Datos");
    this.service.validateToken("UES21-Code " + this.code).subscribe(
      (result) => {
        localStorage.setItem("token", result);
        const oauthDataMode = this.service.getOauthDataMode();
        this.service.GetUserData(oauthDataMode).subscribe(
          (result: UserDataModel) => {
            this.poll.name = result.name;
            this.poll.lastName = result.lastName;
            this.poll.mail = result.primaryEmail;
            this.poll.dni = result.documentNumber.toString();
            this.poll.phone = result.contactPhone;
            this.poll.mobilePhone = result.contactPhone;
            if (result.birthDate) {
              this.poll.birthDate = new Date(result.birthDate);
              this.poll.birthDate.setDate(this.poll.birthDate.getDate() + 1);
            }
            this.poll.rol =
              result.roles.filter((x) => x.name.includes("UE21_ALUMNO"))
                .length > 0
                ? "ALUMNO"
                : "DOCENTE";
            this.poll.userName = result.usrPortal;
            this.resolvedCaptcha = true;
            this.blockUI.stop();
            if (this.authType == 'oauthsis') {
              this.startQuestions();
            }
          },
          (error) => {
            this.blockUI.stop();
            console.error(error);
          }
        );
      },
      (error) => {
        this.blockUI.stop();
        console.error(error);
        this.resolvedCaptcha = false;
      }
    );
  }

  validate(value, regex) {
    const reg = new RegExp(regex);
    return !reg.test(value);
  }

  private _filter(
    value: string
  ): Array<{ id: number; nombre: string; idProvincia: number }> {
    const filterValue = value.toLowerCase();
    return this.cities.filter(
      (option) => option.nombre.toLowerCase().indexOf(filterValue) === 0
    );
  }

  resolved(captchaResponse: string) {
    this.captchaResponse = captchaResponse;
    if (
      captchaResponse != undefined &&
      captchaResponse != null &&
      captchaResponse.length > 0
    ) {
      this.validateToken("Google-Token " + captchaResponse);
    } else {
      this.resolvedCaptcha = false;
    }
  }

  validateToken(token) {
    this.blockUI.start();
    this.service.validateToken(token).subscribe(
      (result) => {
        localStorage.setItem("token", result);
        this.resolvedCaptcha = true;
        this.blockUI.stop();
      },
      (error) => {
        this.blockUI.stop();
        console.error(error);
        this.resolvedCaptcha = false;
      }
    );
  }

  getProvinces() {
    this.service.getProvinces().subscribe(
      (provinces: Array<{ id: number; nombre: string }>) => {
        provinces.forEach((province) => {
          province.nombre = province.nombre
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
        });
        this.provinces = provinces;
      },
      (error) => {
        console.error(error);
      }
    );
  }

  getCities(province: { id: number; nombre: string }) {
    this.poll.province = province.nombre;
    this.service.getCities(province.id).subscribe(
      (cities: Array<{ id: number; nombre: string; idProvincia: number }>) => {
        cities.forEach((city) => {
          city.nombre = city.nombre
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
        });
        this.cities = cities;
      },
      (error) => {
        console.error(error);
      }
    );
  }

  startQuestions() {
    this.poll.listOptions = this.service.getListOptions();
    this.poll.checkboxOptions = this.service.getCheckboxOptions();
    this.poll.customRangeOption = this.service.getCustomRangeOption();
    if (this.poll.dni && this.poll.dni != undefined) {
      this.poll.dni = this.poll.dni.toString();
    }
    this.poll.data = JSON.stringify({
      email: this.poll.mail,
      name: this.poll.name,
      lastName: this.poll.lastName,
      documentType: this.poll.documentType,
      birthDate: this.poll.birthDate,
      dni: this.poll.dni,
      province: this.poll.province,
      city: this.poll.city,
      age: this.poll.age,
      phone: this.poll.phone,
      mobilePhone: this.poll.mobilePhone,
      browser: this.poll.explorer,
      platform: this.poll.model,
      ip: this.poll.ip,
      os: this.poll.os,
      rol: this.poll.rol,
      userName: this.poll.userName,
    });
    this.blockUI.start(
      this.dataService.idPollType == this.idTestVocacionalV2 &&
        this.service.finishPoll
        ? "Obteniendo Resultados"
        : "Cargando Preguntas"
    );
    this.service.updatePoll(this.poll).subscribe(
      (result) => {
        this.dataService.registerPoll = this.poll;
        this.dataService.questionsData = result;
        if (
          this.dataService.idPollType == this.idTestVocacionalV2 &&
          this.service.finishPoll
        ) {
          this.sendResults();
        } else {
          this.service.startPoll = true;
          this.router.navigate(["/questions"]);
          this.blockUI.stop();
        }
      },
      (error) => {
        this.blockUI.stop();
        console.error(error);
      }
    );
  }

  sendResults() {
    const req = {
      InstanceId: this.poll.instanceId,
      PollId: this.poll.id,
    };
    this.service.sendResults(req).subscribe(
      (result) => {
        this.dataService.finishedPoll = result;
        localStorage.removeItem("token");
        this.service.finishPoll = true;
        this.router.navigate(["/summary"]);
        this.blockUI.stop();
      },
      (error) => {
        this.blockUI.stop();
        console.error(error);
      }
    );
  }
}
