import { JsonObject, JsonProperty } from 'json2typescript';
import { PollsModel } from './polls.model';

@JsonObject('PossibleAnswersModel')
export class PossibleAnswersModel {
  @JsonProperty('idPossibleAnswers', Number)
  idPossibleAnswers: number = undefined;
  @JsonProperty('value', String)
  value: string = undefined;
}
