import { AfterViewInit, Component, ElementRef, OnInit } from "@angular/core";
import { ActivatedRoute, Router, NavigationExtras } from "@angular/router";
import {
  FocusesStructure,
  QuestionsStructure,
  PollStructure,
} from "src/models/pollStructure.model";
import { PollsService } from "src/services/polls.service";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { DataService } from "src/services/data.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { FormControl } from "@angular/forms";
import { debounceTime, finalize, switchMap, tap } from "rxjs/operators";
import { PossibleAnswersModel } from "src/models/possibleAnswers.model";
import { MatStepper } from "@angular/material/stepper";

@Component({
  selector: "app-questions",
  templateUrl: "./questions.component.html",
  styleUrls: ["./questions.component.scss"],
})
export class QuestionsComponent implements OnInit, AfterViewInit {
  @BlockUI() blockUI: NgBlockUI;

  saving = false;
  values: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  listOptions: number = 0;
  rangeValueOption: number = 0;
  yesNoOptions: number = 0;
  textOptions: number = 0;
  checkboxOptions: number = 0;
  labelOptions: number = 0;
  autocomplete: number = 0;
  customRangeOption: number = 0;
  pollStructure: PollStructure = new PollStructure();

  filteredAnswers: PossibleAnswersModel[] = [];
  isLoading = false;
  autocompleteInput = new FormControl();

  autocompleteIdQuestion: number = 0;
  autocompleteQuestionValueText: string = "";
  idTestVocacionalV2: number;
  backgroundCardColor: any = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: PollsService,
    public dataService: DataService,
    private _snackBar: MatSnackBar,
    private elem: ElementRef
  ) {
    this.listOptions = parseInt(this.service.getListOptions());
    this.rangeValueOption = parseInt(this.service.getRangeValue());
    this.textOptions = parseInt(this.service.getTextOptions());
    this.yesNoOptions = parseInt(this.service.getYesNoOptions());
    this.checkboxOptions = parseInt(this.service.getCheckboxOptions());
    this.autocomplete = parseInt(this.service.getAutocompleteOptions());
    this.labelOptions = parseInt(this.service.getLabelOptions());
    this.customRangeOption = parseInt(this.service.getCustomRangeOption());
    var header = document.getElementById("header");
    if (header) {
      header.style.display = "none";
    }
    this.dataService.setFooterLogo("./assets/img/Logo_Siglo_blanco.svg");
    // var footer = document.getElementById("footer");
    // footer.style.width = "20%";
  }
  ngAfterViewInit(): void {
    this.pollStructure.focuses.forEach((x) => {
      x.questions.forEach((y) => {
        if (y.idQuestionType == this.autocomplete) {
          this.autocompleteIdQuestion = y.id;
          this.autocompleteInput.setValue({
            idPossibleAnswers: y.value,
            value: y.valueText,
          });
          // this.autocompleteQuestionValueText = autocomplete.valueText;
        }
      });
    });
    if (this.pollStructure.focuses.length <= 1) {
      const aux = document.getElementsByClassName(
        "mat-horizontal-stepper-header-container"
      );
      for (let i = 0; i < aux.length; i++) {
        (aux[i] as HTMLElement).style.display = "none";
      }
    }
  }

  ngOnInit(): void {
    this.idTestVocacionalV2 = parseInt(this.service.getIdTestVocacionalV2());
    this.pollStructure = this.dataService.questionsData;
    this.pollStructure.focuses.forEach((focus) => {
      focus.questions.forEach((question) => {
        if (
          question.idQuestionType == this.checkboxOptions &&
          question.valueText &&
          question.valueText.length > 0
        ) {
          let values = question.valueText.split(",");
          question.questionListOptions.forEach((options) => {
            if (values.find((x) => x == options.id.toString())) {
              options.selected = true;
            }
          });
          question.valueText = values.toString();
        }
      });
    });
    this.autocompleteInput.valueChanges
      .pipe(
        debounceTime(1000),
        tap(() => (this.isLoading = true)),
        switchMap((value) =>
          this.service
            .getAnswersAsync(this.autocompleteIdQuestion, value)
            .pipe(finalize(() => (this.isLoading = false)))
        )
      )
      .subscribe((users) => {
        this.filteredAnswers = users;
      });
    this.setValues();
    // if (this.dataService.idPollType == this.idTestVocacionalV2) {
      this.setBackgroundAndShowMessage(this.elem, 0);
    // }
      this.setHeadersIcons();

    this.backgroundCardColor = this.service.getbackgroundColor();
  }

  setHeadersIcons() {
    setTimeout(() => {
      this.pollStructure.focuses.forEach(function (value, index) {
        if (value.icon) {
          document.getElementById('matStepperIcon_' + index).innerHTML = "<img src=\""+ value.icon + "\" class=\"stepIcon\" />"
        } else {
          document.getElementById('matStepperIcon_' + index).innerHTML = "<span>" + (index + 1) + "</span>"
        }
      })
    }, 1);
  }

  showQuestion(questionToShow: QuestionsStructure) {
    var questionToValidate: QuestionsStructure;
    for (const focuse of this.pollStructure.focuses) {
      questionToValidate = focuse.questions.find(
        (question) => question.id == questionToShow.questionToCheck
      );
      if (questionToValidate) {
        break;
      }
    }
    if (
      (questionToValidate && questionToValidate.valueText) ||
      questionToValidate.value
    ) {
      const value = questionToValidate.valueText
        ? questionToValidate.valueText
        : questionToValidate.value.toString();
      switch (questionToShow.conditionToCheck) {
        case "CONTAIN":
          return value.includes(questionToShow.valueToCheck);
        case ">":
          return value > questionToShow.valueToCheck;
        case ">=":
          return value >= questionToShow.valueToCheck;
        case "<":
          return value < questionToShow.valueToCheck;
        case "<=":
          return value <= questionToShow.valueToCheck;
        case "==":
          return value == questionToShow.valueToCheck;
        default:
          return false;
      }
    }
    return false;
  }

  canGo(questions: Array<QuestionsStructure>) {
    // return questions.find((question) => (question.idQuestionType != this.textOptions && question.value == null) || (question.idQuestionType == this.textOptions && question.valueText == null) ? false : true)
    const aux = questions.find((question) =>
      question.idQuestionType == this.labelOptions
        ? false
        : question.hasCondition && !this.showQuestion(question)
        ? false
        : question.idQuestionType == this.textOptions ||
          question.idQuestionType == this.customRangeOption
        ? question.valueText == null || question.valueText.length <= 0
        : question.idQuestionType == this.checkboxOptions
        ? question.questionListOptions.filter((option) => option.selected)
            .length <= 0
        : question.valueText == null && question.value == null
    );
    return aux ? false : true;
  }

  previousStep(stepper: MatStepper, index: number) {
    stepper.previous();
    this.setBackgroundAndShowMessage(this.elem, index - 1);
    this.setHeadersIcons();
  }

  nextStep(stepper: MatStepper, index: number) {
    if (!this.saving) {
      this.save(() => {
        stepper.next();
        this.setBackgroundAndShowMessage(this.elem, index + 1);
        this.setHeadersIcons();
      });
    }
  }

  setBackgroundAndShowMessage(elem: ElementRef, index: number) {
    let mainContainer = document.getElementById("mainContainer");
    if (mainContainer) {
      const img = this.pollStructure.focuses[index].image;
      const msg = this.pollStructure.focuses[index].message;
      const color = this.pollStructure.focuses[index].color;
      if (img && img.length > 0) {
        mainContainer.style.background =
          "url(" + img + ") no-repeat center center fixed";
        mainContainer.style.backgroundSize = "cover";
        if (this.dataService.idPollType == this.idTestVocacionalV2) {
          var cols = document.getElementsByClassName("optionsContainer");
          for (let i = 0; i < cols.length; i++) {
            (cols[i] as HTMLElement).style.backgroundImage =
              "url(" + img.replace("backgrounds", "bottoms") + ")";
            (cols[i] as HTMLElement).style.backgroundRepeat = "round";
            // background-image: url(./../../assets/img/resources/bottoms/linguistica.png);
            // background-repeat: round;
          }
        }
      } else {
        mainContainer.style.background = "none";
      }
      if (color) {
        elem.nativeElement.style.setProperty('--text-color', color);
      } else {
        elem.nativeElement.style.setProperty('--text-color', "#000000")
      }
      if (msg && msg.length > 0) {
        this._snackBar.open(this.replaceValues(msg), "Aceptar", {
          duration: 10000,
          horizontalPosition: "center",
          verticalPosition: "bottom",
        });
      }
    }
  }

  save(callback, lastQuestion = false) {
    this.saving = true;
    this.pollStructure.focuses.forEach((focus) => {
      focus.questions.forEach((question) => {
        if (question.idQuestionType == this.checkboxOptions) {
          let values = [];
          question.questionListOptions.forEach((options) => {
            if (options.selected) {
              values.push(options.id);
            }
          });
          question.valueText = values.toString();
        }
        if (question.isPersonalData) {
          this.dataService.registerPoll[question.personalData] =
            question.valueText;
        }
      });
    });
    this.service
      .updatePoll(this.dataService.registerPoll)
      .subscribe((data) => {});
    this.service.save(this.pollStructure, lastQuestion).subscribe(
      (result) => {
        this.setValues();
        this.saving = false;
        if (callback) {
          callback();
        }
      },
      (error) => {
        this._snackBar.open(
          "Ocurrió un error al guardar la encuesta, por favor, intente nuevamente",
          "Aceptar",
          {
            duration: 8000,
            horizontalPosition: "center",
            verticalPosition: "top",
          }
        );
        this.saving = false;
        console.error(error);
      }
    );
  }

  setValues() {
    this.pollStructure.focuses.forEach((focus) => {
      focus.focus = this.replaceValues(focus.focus);
      focus.questions.forEach((question) => {
        question.question = this.replaceValues(question.question);
      });
    });
  }

  replaceValues(text) {
    if (text) {
      var regex = new RegExp("{{question_value:([0-9]?)+}}");
      if (regex.test(text)) {
        const param = regex.exec(text)[0];
        const questionId = parseInt(param.split(":")[1].slice(0, -2));
        let questionValue = "";
        this.pollStructure.focuses.forEach((aux) => {
          const questionAux = aux.questions.find((x) => x.id == questionId);
          if (questionAux) {
            questionValue = questionAux.value
              ? questionAux.value.toString()
              : questionAux.valueText;
          }
        });
        return text.replace(param, questionValue);
      } else {
        if (text.includes("{{name}}")) {
          text = text.replace("{{name}}", this.dataService.registerPoll.name);
        }
        if (text.includes("{{lastName}}")) {
          text = text.replace(
            "{{lastName}}",
            this.dataService.registerPoll.lastName
          );
        }
        if (text.includes("{{email}}")) {
          text = text.replace("{{email}}", this.dataService.registerPoll.mail);
        }
        return text;
      }
    } else {
      return text;
    }
  }

  setValue(question: QuestionsStructure, value: number) {
    question.value = value;
    for (let i = 0; i < 10; i++) {
      const button = document.getElementById(
        "questionOption_" + question.id + "_" + i
      );
      button.style.backgroundImage =
        i < value
          ? "url('./../../assets/img/resources/Estrelladorada.png')"
          : "url('./../../assets/img/resources/Estrellagris.png')";
    }
  }

  finishPoll() {
    if (this.dataService.idPollType == this.idTestVocacionalV2) {
      this.save(() => {
        this.service.finishPoll = true;
        this.router.navigate(["/personalInformation"]);
      }, true);
    } else {
      this.blockUI.start();
      this.pollStructure.focuses.forEach((focus) => {
        focus.questions.forEach((question) => {
          if (question.idQuestionType == this.checkboxOptions) {
            let values = [];
            question.questionListOptions.forEach((options) => {
              if (options.selected) {
                values.push(options.id);
              }
            });
            question.valueText = values.toString();
          }
        });
      });
      this.service.pollResponses(this.pollStructure).subscribe(
        (result) => {
          this.dataService.finishedPoll = result;
          localStorage.removeItem("token");
          this.blockUI.stop();
          this.service.finishPoll = true;
          this.router.navigate(["/summary"]);
        },
        (error) => {
          this.blockUI.stop();
          this._snackBar.open(
            "Ocurrió un error al finalizar la encuesta, por favor, intente nuevamente",
            "Aceptar",
            {
              duration: 8000,
              horizontalPosition: "center",
              verticalPosition: "top",
            }
          );
          console.error(error);
        }
      );
    }
  }

  validRegex(value) {
    var regex = new RegExp("{{question_value:([0-9]?)+}}");
    return regex.test(value);
  }

  displayFn(answer: PossibleAnswersModel) {
    if (answer) {
      return answer.value;
    }
  }

  selectedOption(value) {
    return value.option.value;
  }
}
