import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PollsModel } from 'src/models/polls.model';
import { PersonalInformation, ResultItem, UserDataFromProcessModel } from 'src/models/user-personal-information.model';
import { UserDataModel } from 'src/models/userDataModel.model';
import { DataService } from 'src/services/data.service';
import { HelperService } from 'src/services/helper.service';
import { PollsService } from 'src/services/polls.service';

@Component({
  selector: 'app-user-polls',
  templateUrl: './user-polls.component.html',
  styleUrls: ['./user-polls.component.scss']
})
export class UserPollsComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  personalInformation: UserDataFromProcessModel;
  filteredPolls: Array<ResultItem> = [];
  idPollType: number;
  poll: PollsModel = new PollsModel();
  noPendingPollsMessage: string = "";

  currentDate = new Date();


  provinces: Array<{ id: number; nombre: string }> = [];
  cities: Array<{ id: number; nombre: string; idProvincia: number }> = [];
  filteredOptions: Observable<
    Array<{ id: number; nombre: string; idProvincia: number }>
  >;

  userDataForm = new FormGroup({
    // name: new FormControl("", [Validators.required, Validators.pattern('^([ a-zA-Z\'])+$')]),
    name: new FormControl("", [Validators.required]),
    // lastName: new FormControl("", [Validators.required, Validators.pattern('^([ a-zA-Z\'])+$')]),
    mail: new FormControl("", [Validators.required]),
    // dni: new FormControl("", [Validators.required, Validators.pattern('^\\d{8}(?:[-\\s]\\d{4})?$')]),
    // mobilePhone: new FormControl("", [Validators.required, Validators.pattern('^[0-9]+$')]),
    // birthDate: new FormControl("", [Validators.required]),
    // province: new FormControl("", [Validators.required]),
    // city: new FormControl("", [Validators.required]),
  });
  availableRetries: number = 0;

  constructor(
    private router: Router,
    public service: PollsService,
    public dataService: DataService,
    private helperService: HelperService) {
    this.noPendingPollsMessage = environment.noPendingPollsMessage;
    this.idPollType = parseInt(localStorage.getItem("idPollType"));
  }

  ngOnInit(): void {
    this.getUserData();
    // this.getProvinces();
    // this.filteredOptions = this.userDataForm.controls['city'].valueChanges.pipe(
    //   startWith(""),
    //   map((value) => this._filter(value))
    // );
    // this.userDataForm.controls['province'].valueChanges.subscribe((value) => {
    //   this.getCities(value);
    //   this.poll.province = this.provinces.find(pr => pr.id == value).nombre;
    // })
    // this.userDataForm.controls['city'].valueChanges.subscribe((value) => {
    //   this.poll.city = this.cities.find(pr => pr.id == value).nombre;
    // })
    this.poll.explorer = this.helperService.getNavigator();
    const platform = this.helperService.getPlatform();
    this.poll.model = platform.model;
    this.poll.os = platform.os;
  }

  // private _filter(
  //   value: string
  // ): Array<{ id: number; nombre: string; idProvincia: number }> {
  //   const filterValue = value.toLowerCase();
  //   return this.cities.filter(
  //     (option) => option.nombre.toLowerCase().indexOf(filterValue) === 0
  //   );
  // }

  // getProvinces() {
  //   this.service.getProvinces().subscribe(
  //     (provinces: Array<{ id: number; nombre: string }>) => {
  //       provinces.forEach((province) => {
  //         province.nombre = province.nombre
  //           .normalize("NFD")
  //           .replace(/[\u0300-\u036f]/g, "");
  //       });
  //       this.provinces = provinces;
  //     },
  //     (error) => {
  //       console.error(error);
  //     }
  //   );
  // }

  // getCities(provinceId) {
  //   // this.poll.province = province.nombre;
  //   this.service.getCities(provinceId).subscribe(
  //     (cities: Array<{ id: number; nombre: string; idProvincia: number }>) => {
  //       cities.forEach((city) => {
  //         city.nombre = city.nombre
  //           .normalize("NFD")
  //           .replace(/[\u0300-\u036f]/g, "");
  //       });
  //       this.cities = cities;
  //     },
  //     (error) => {
  //       console.error(error);
  //     }
  //   );
  // }

  getUserData() {
    this.blockUI.start("Cargando Datos");
    this.service.getUserDataFromProcess(this.idPollType).subscribe((res: UserDataFromProcessModel) => {
      this.blockUI.stop();
      this.service.registerPoll = true;
      if (res.resultItems.resultItem.length <= 0 || (res.resultItems.resultItem.length == 1 && !res.resultItems.resultItem[0].id)) {
        setTimeout(() => {
          window.top.postMessage('closeReminder','*');
        }, parseInt(environment.noPendingPollsMessageTimeOut))
      } else {
        this.personalInformation = res;
        this.userDataForm.controls['name'].setValue(res.personalInformation.fullName);
        this.userDataForm.controls['mail'].setValue(res.personalInformation.email);
        this.availableRetries = this.personalInformation.maxRetries - this.personalInformation.retries;
        // if (this.availableRetries <= 0) window.top.postMessage('hideCloseReminder','*');;
        const aux = this.personalInformation.dataItems.dataItem.find(x => x.type == 'ComboBox');
        this.personalInformation.dataItems.dataItem.find(x => x.type == 'ComboBox').props = aux.options.option[0].id;
        this.carreerChange(this.personalInformation.dataItems.dataItem.find(x => x.type == 'ComboBox').props);
      }
    }, (error) => {
      this.blockUI.stop();
      console.error(error);
    })
    // const oauthDataMode = this.service.getOauthDataMode();
    // this.service.GetUserData(oauthDataMode).subscribe(
    //   (result: UserDataModel) => {
    //     this.userDataForm.controls['name'].setValue(result.name);
    //     this.userDataForm.controls['lastName'].setValue(result.lastName);
    //     this.userDataForm.controls['mail'].setValue(result.primaryEmail);
    //     this.userDataForm.controls['dni'].setValue(result.documentNumber.toString());
    //     this.userDataForm.controls['mobilePhone'].setValue(result.contactPhone);
    //     if (result.birthDate) {
    //       let date = new Date(result.birthDate);
    //       date.setDate(date.getDate() + 1);
    //       this.userDataForm.controls['birthDate'].setValue(date);
    //     }
    //     this.poll.rol =
    //       result.roles.filter((x) => x.name.includes("UE21_ALUMNO"))
    //         .length > 0
    //         ? "ALUMNO"
    //         : "DOCENTE";
    //     this.poll.userName = result.usrPortal;
    //     this.blockUI.stop();
    //   },
    //   (error) => {
    //     this.blockUI.stop();
    //     console.error(error);
    //   }
    // );
  }

  carreerChange(carreerId: string) {
    this.filteredPolls = this.personalInformation.resultItems.resultItem.filter((poll) => poll.optionId == carreerId);
  }

  startQuestions(instanceId) {
    this.poll.instanceId = instanceId;
    this.poll.listOptions = this.service.getListOptions();
    this.poll.checkboxOptions = this.service.getCheckboxOptions();
    this.poll.customRangeOption = this.service.getCustomRangeOption();
    Object.keys(this.userDataForm.value).forEach(element => {
      // console.log(this.userDataForm.value[element]);
      this.poll[element] = this.userDataForm.value[element];
    });
    // this.poll.data = JSON.stringify({
    //   email: this.poll.mail,
    //   name: this.poll.name,
    //   lastName: this.poll.lastName,
    //   documentType: this.poll.documentType,
    //   birthDate: this.poll.birthDate,
    //   dni: this.poll.dni,
    //   province: this.poll.province,
    //   city: this.poll.city,
    //   age: this.poll.age,
    //   phone: this.poll.phone,
    //   mobilePhone: this.poll.mobilePhone,
    //   browser: this.poll.explorer,
    //   platform: this.poll.model,
    //   ip: this.poll.ip,
    //   os: this.poll.os,
    //   rol: this.poll.rol,
    //   userName: this.poll.userName,
    // });
    this.blockUI.start("Cargando Preguntas");
    // console.log(this.poll);
    this.service.updatePoll(this.poll).subscribe(
      (result) => {
        this.dataService.registerPoll = this.poll;
        this.dataService.questionsData = result;
        this.service.startPoll = true;
        this.router.navigate(["/questions"]);
        this.blockUI.stop();
      },
      (error) => {
        this.blockUI.stop();
        console.error(error);
      }
    );
  }

  postpone() {
    this.blockUI.start("Cargando");
    this.service.postponePoll(this.idPollType).subscribe((res) => {
      console.log(res);
      if (res.code.toLowerCase() == "ok") {
        window.top.postMessage('closeReminder','*');
      }
      this.blockUI.stop();
    }, (error) => {
      console.error(error);
      this.blockUI.stop();
    })
  }

}
