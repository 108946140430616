<block-ui>
  <div class="container">
    <div class="row buttonsArea">
      <div class="button">
        <button mat-raised-button color="primary" (click)="start(idEncuestaCovidAlumno, 109)"
          [disabled]="showCaptcha && !resolvedCaptcha">
          <div style="display: flex;
          flex-direction: column;
          justify-content: space-evenly;">
            <img src="./assets/img/student.png" height="128">
            Alumno / Docente
          </div>
        </button>
      </div>
      <div class="button">
        <button mat-raised-button color="primary" (click)="start(idEncuestaCovidDocente, 109)"
          [disabled]="showCaptcha && !resolvedCaptcha">
          <div style="display: flex;
          flex-direction: column;
          justify-content: space-evenly;">
            <img src="./assets/img/teachers.png" height="128">
            Co-Docente
          </div>
        </button>
      </div>
      <div class="button">
        <button mat-raised-button color="primary" (click)="start(idEncuestaCovidExterno, 109)"
          [disabled]="showCaptcha && !resolvedCaptcha">
          <div style="display: flex;
          flex-direction: column;
          justify-content: space-evenly;">
            <img src="./assets/img/external.png" height="128">
            Externo
          </div>
        </button>
      </div>
    </div>
    <!-- <div class="row">
      <button mat-raised-button color="primary" (click)="finish()">finishReminder</button>
      <button mat-raised-button color="primary" (click)="hide()">hideCloseReminder</button>
      <button mat-raised-button color="primary" (click)="close()">closeReminder</button>
    </div> -->
    <div class="row" style="display: flex; justify-content: center; padding-top: 50px;" *ngIf="showCaptcha">
      <!-- Production / QA -->
      <re-captcha (resolved)="resolved($event)" siteKey="6LcHYcMZAAAAAFYNbLW0wKtuS3V35Rg_myq3BxPq">
        <!-- Dev -->
        <!-- <re-captcha (resolved)="resolved($event)" siteKey="6LfZMrUZAAAAANeg8-LQyn2qD2gQr2e_uDldItSt"> -->
      </re-captcha>
    </div>
  </div>
</block-ui>
