import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('QuestionsOptionsStructure')
export class QuestionsOptionsStructure {
  @JsonProperty('active', Boolean)
  active: boolean = undefined;
  @JsonProperty('id', Number)
  id: number = undefined;
  @JsonProperty('key', String)
  key: string = undefined;
  @JsonProperty('value', String)
  value: string = undefined;
  @JsonProperty('image', String)
  image: string = undefined;

  selected: boolean = false;
}

@JsonObject('QuestionsStructure')
export class QuestionsStructure {
  @JsonProperty('id', Number)
  id: number = undefined;
  @JsonProperty('question', String)
  question: string = undefined;
  @JsonProperty('value', Number)
  value: number = undefined;
  @JsonProperty('idQuestionType', Number, true)
  idQuestionType: number = undefined;
  @JsonProperty('valueText', String, true)
  valueText: string = undefined;
  @JsonProperty('conditionToCheck', String, true)
  conditionToCheck: string = undefined;
  @JsonProperty('hasCondition', Boolean, true)
  hasCondition: boolean = false;
  @JsonProperty('questionToCheck', Number, true)
  questionToCheck: number = undefined;
  @JsonProperty('valueToCheck', String, true)
  valueToCheck: string = undefined;
  @JsonProperty('isPersonalData', Boolean, true)
  isPersonalData: boolean = false;
  @JsonProperty('personalData', String, true)
  personalData: string = undefined;
  @JsonProperty('questionListOptions', [QuestionsOptionsStructure], true)
  questionListOptions: Array<QuestionsOptionsStructure> = [];
}

@JsonObject('FocusesStructure')
export class FocusesStructure {
  @JsonProperty('focus', String)
  focus: string = undefined;
  @JsonProperty('title', String, true)
  title: string = undefined;
  @JsonProperty('message', String, true)
  message: string = undefined;
  @JsonProperty('icon', String, true)
  icon: string = undefined;
  @JsonProperty('image', String, true)
  image: string = undefined;
  @JsonProperty('color', String, true)
  color: string = undefined;
  @JsonProperty('scope', String, true)
  scope: string = undefined;
  @JsonProperty('questions', [QuestionsStructure])
  questions: Array<QuestionsStructure> = undefined;
}

@JsonObject('PollStructure')
export class PollStructure {
  @JsonProperty('pollId', Number)
  pollId: number = undefined;
  @JsonProperty('instanceId', String)
  instanceId: string = undefined;
  @JsonProperty('focuses', [FocusesStructure])
  focuses: Array<FocusesStructure> = [];
}
