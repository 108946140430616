<block-ui>
  <div class="container" *ngIf="service.registerPoll">
    <div class="formContainer">
      <div class="row" *ngIf="personalInformation">
        <div class="col-md-12">
          <mat-card>
            <mat-card-content>
              <form [formGroup]="userDataForm" *ngIf="userDataForm && userDataForm.controls">
                <div class="row">
                  <div class="col-md-6 col-sm-6 col-xs-12">
                    <mat-form-field class="form-full-width">
                      <mat-label>Nombre y Apellido</mat-label>
                      <input matInput type="text" formControlName="name" placeholder="Ej. Julieta" readonly>
                    </mat-form-field>
                    <!-- <mat-error align="start"
                      *ngIf="userDataForm.controls['name'] && userDataForm.controls['name'].dirty && userDataForm.controls['name'].errors">
                      Solo se permiten letras
                    </mat-error> -->
                  </div>
                  <div class="col-md-6 col-sm-6 col-xs-12">
                    <mat-form-field class="form-full-width">
                      <mat-label>Correo Electrónico</mat-label>
                      <input matInput type="email" formControlName="mail" placeholder="Ej. ejemplo@mail.com" readonly>
                        <!-- <mat-error align="start"
                          *ngIf="userDataForm.controls['mail'] && userDataForm.controls['mail'].dirty && userDataForm.controls['mail'].errors">
                          Ingrese un email valido
                        </mat-error> -->
                    </mat-form-field>
                  </div>
                  <!-- <div class="col-md-6 col-sm-6 col-xs-12">
                    <mat-form-field class="form-full-width">
                      <mat-label>Apellido</mat-label>
                      <input matInput type="text" formControlName="lastName" placeholder="Ej. Lopez">
                    </mat-form-field>
                    <mat-error align="start"
                      *ngIf="userDataForm.controls['lastName'] && userDataForm.controls['lastName'].dirty && userDataForm.controls['lastName'].errors">
                      Solo se permiten letras
                    </mat-error>
                  </div> -->
                </div>
                <!-- <div class="row">
                  <div class="col-md-6 col-sm-6 col-xs-12">
                    <mat-form-field class="form-full-width">
                      <mat-label>DNI</mat-label>
                      <input matInput type="number" formControlName="dni" placeholder="Ej. 11111111">
                    </mat-form-field>
                    <mat-error align="start"
                      *ngIf="userDataForm.controls['dni'] && userDataForm.controls['dni'].dirty && userDataForm.controls['dni'].errors">
                      Por favor compruebe su numero de documento
                    </mat-error>
                  </div>
                  <div class="col-md-6 col-sm-6 col-xs-12">
                    <mat-form-field class="form-full-width">
                      <mat-label>Fecha de Nacimiento</mat-label>
                      <input matInput [matDatepicker]="picker" [max]="currentDate" formControlName="birthDate">
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div> -->
                <!-- <div class="row">
                  <div class="col-md-6 col-sm-6 col-xs-12">
                    <mat-form-field class="form-full-width">
                      <mat-label>Teléfono Movil</mat-label>
                      <input matInput type="tel" formControlName="mobilePhone" placeholder="1155512346">
                    </mat-form-field>
                    <mat-error align="start"
                      *ngIf="userDataForm.controls['mobilePhone'] && userDataForm.controls['mobilePhone'].dirty && userDataForm.controls['mobilePhone'].errors">
                      Por favor ingrese solo números
                    </mat-error>
                  </div>
                </div> -->
                <!-- <div class="row">
                  <div class="col-md-6 col-sm-6 col-xs-12">
                    <mat-form-field class="form-full-width">
                      <mat-label>Provincia</mat-label>
                      <mat-select formControlName="province">
                        <mat-option *ngFor="let province of provinces" [value]="province.id">
                          {{province.nombre}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-md-6 col-sm-6 col-xs-12">
                    <mat-form-field class="form-full-width">
                      <mat-label>Localidad</mat-label>
                      <input matInput type="text" formControlName="city" placeholder="Ej. Córdoba"
                        [matAutocomplete]="auto" [(ngModel)]="poll.city"
                        [readonly]="poll.province == null || poll.province == undefined || poll.province.length <= 0">
                      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                        <mat-option *ngFor="let city of filteredOptions | async" [value]="city.nombre">
                          {{city.nombre}}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                </div> -->
              </form>
            </mat-card-content>
          </mat-card>
          <mat-card>
            <mat-card-header>
              <mat-card-title>{{ personalInformation.titulo.value }}</mat-card-title>
              <mat-card-subtitle>{{ personalInformation.subTitulo.value }}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <div class="row">
                <div class="col-md-6" *ngFor="let item of personalInformation.dataItems.dataItem">
                  <div class="textContainer" *ngIf="item.type == 'Label'">
                    <mat-label>{{ item.value }}: {{item.props}}</mat-label>
                  </div>
                  <mat-form-field class="form-full-width" *ngIf="item.type == 'Input'">
                    <mat-label>{{ item.value }}</mat-label>
                    <input matInput type="text" [(ngModel)]="item.props" readonly>
                  </mat-form-field>
                  <mat-form-field class="form-full-width" *ngIf="item.type == 'ComboBox'">
                    <mat-label>{{ item.value }}</mat-label>
                    <mat-select (selectionChange)="carreerChange($event.value)" required [(ngModel)]="item.props">
                      <mat-option *ngFor="let option of item.options.option" [value]="option.id">
                        {{ option.value }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="row" *ngIf="filteredPolls && filteredPolls.length > 0">
                <div class="col-md-12">
                  <mat-card *ngIf="personalInformation">
                    <mat-card-header>
                      <mat-card-title>Encuestas Pendientes de responder</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                      <div class="row text_align" *ngFor="let poll of filteredPolls">
                        <div class="col-md-2 col-sm-3 col-xs-12">
                          <button mat-raised-button color="primary" (click)="startQuestions(poll.id)">Responder ahora</button>
                        </div>
                        <div class="col-md-10 col-sm-9 col-xs-12">
                          <span>
                            {{ poll.value }}
                          </span>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>
              </div>
            </mat-card-content>
            <mat-card-actions align=end class="text_align">
              <mat-label>{{ availableRetries > 0 ? 'Puedes posponer ' + availableRetries + ' veces' : 'Ya no puedes posponer esta encuesta'}}</mat-label>
              <button mat-raised-button color="primary"
                matTooltip="{{ availableRetries > 0 ? 'Puedes posponer ' + availableRetries + ' veces' : 'Ya no puedes posponer esta encuesta'}}"
                [disabled]="!personalInformation.maxRetries || personalInformation.retries >= personalInformation.maxRetries" (click)="postpone()">Posponer</button>
            </mat-card-actions>
          </mat-card>
        </div>
      </div>
      <div class="row" *ngIf="!personalInformation">
        <div class="col-md-12">
          <mat-card class="textContainer">
            <h1>
              {{noPendingPollsMessage}}
            </h1>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</block-ui>