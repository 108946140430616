// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production:  window["env"]["production"] || "default",
  api_url: window["env"]["apiUrl"] || "default",
  testDomain: window["env"]["testDomain"] || "default",
  ddjjDomain: window["env"]["ddjjDomain"] || "default",
  listOptions: window["env"]["listOptions"] || "default",
  textOptions: window["env"]["textOptions"] || "default",
  rangeValueOption: window["env"]["rangeValueOption"] || "default",
  yesNoOptions : window["env"]["yesNoOptions"] || "default",
  checkboxOptions : window["env"]["checkboxOptions"] || "default",
  autocompleteOptions : window["env"]["autocompleteOptions"] || "default",
  labelOptions : window["env"]["labelOptions"] || "default",
  customRangeOption : window["env"]["customRangeOption"] || "default",
  ues21Api_url: window["env"]["ues21ApiUrl"] || "default",
  client_id: window["env"]["clientId"] || "default",
  idTestVocacional: window["env"]["idTestVocacional"] || "default",
  idTestVocacionalV2: window["env"]["idTestVocacionalV2"] || "default",
  defaultIdTest: window["env"]["defaultIdTest"] || "default",
  idEncuestaCovidAlumno: window["env"]["idEncuestaCovidAlumno"] || "default",
  idEncuestaCovidDocente: window["env"]["idEncuestaCovidDocente"] || "default",
  idEncuestaCovidExterno: window["env"]["idEncuestaCovidExterno"] || "default",
  showCaptcha: window["env"]["showCaptcha"] || "default",
  useDefaultPoll: window["env"]["useDefaultPoll"] || "false",
  oauthDataMode: window["env"]["oauthDataMode"] || "default",
  version: window["env"]["version"] || "default",
  backgroundColor: window["env"]["backgroundColor"] || "default",
  noPendingPollsMessage: window["env"]["noPendingPollsMessage"] || "default",
  noPendingPollsMessageTimeOut: window["env"]["noPendingPollsMessageTimeOut"] || "default"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
