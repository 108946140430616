import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FinishedPoll } from 'src/models/finishedPoll.model';
import { PollsModel } from 'src/models/polls.model';
import { PollStructure } from 'src/models/pollStructure.model';
import { PollVars } from 'src/models/pollVars.model';
import { PossibleAnswersModel } from 'src/models/possibleAnswers.model';
import { UserDataModel } from 'src/models/userDataModel.model';

import { BaseApiService } from './base.api.service';
import { ValidateUserResult } from 'src/models/validate-user-result.model';
import { UserDataFromProcessModel } from 'src/models/user-personal-information.model';

@Injectable({
  providedIn: 'root'
})
export class PollsService extends BaseApiService {
  public startPoll = false;
  public registerPoll = false;
  public finishPoll = false;

  constructor(http: HttpClient) {
    super(http);
  }

  getListOptions() {
    return this.listOptions;
  }

  getRangeValue() {
    return this.rangeValueOption;
  }

  getTextOptions() {
    return this.textOptions;
  }

  getYesNoOptions() {
    return this.yesNoOptions;
  }

  getAutocompleteOptions() {
    return this.autocompleteOptions;
  }

  getCheckboxOptions() {
    return this.checkboxOptions;
  }

  getLabelOptions() {
    return this.labelOptions;
  }

  getCustomRangeOption() {
    return this.customRangeOption;
  }

  getTestDomain() {
    return this.testDomain;
  }

  getDDJJDomain() {
    return this.ddjjDomain;
  }

  getUes21ApiUrl() {
    return this.ues21ApiUrl;
  }

  getClientId() {
    return this.clientId;
  }

  getDefaultIdTest() {
    return this.defaultIdTest;
  }

  getIdTestVocacional() {
    return this.idTestVocacional;
  }

  getIdTestVocacionalV2() {
    return this.idTestVocacionalV2;
  }

  getIdEncuestaCovidAlumno() {
    return this.idEncuestaCovidAlumno;
  }

  getIdEncuestaCovidDocente() {
    return this.idEncuestaCovidDocente;
  }

  getIdEncuestaCovidExterno() {
    return this.idEncuestaCovidExterno;
  }

  getShowCaptcha() {
    return this.showCaptcha;
  }

  getUseDefaultPoll() {
    return this.useDefaultPoll;
  }

  getOauthDataMode() {
    return this.oauthDataMode;
  }

  getVersion() {
    return this.version;
  }

  getbackgroundColor() {
    return this.backgroundColor;
  }

  isProduction() {
    return this.production;
  }

  getAuthType(idPollType = 0, idCampaign = 0): Observable<any> {
    let _url = "PollUes21/GetPollVars";
    _url += '?idPollType=' + idPollType;
    _url += '&idCampaign=' + idCampaign;
    return this._get(this.baseUrl + _url, PollVars, null);
  }

  GetUserData(oauthDataMode: number): Observable<any> {
    const token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      "Authorization": "Basic " + token,
      "Content-Type": "application/json"
    });
    let _url = "PollUes21/ues21/GetUserData";
    _url += "?oauthDataMode=" + oauthDataMode;
    return this._get(this.baseUrl + _url, UserDataModel, false, headers);
  }

  getUserDataFromProcess(pollTypeId: number): Observable<any> {
    const token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      "Authorization": "Basic " + token,
      "Content-Type": "application/json"
    });
    let _url = "PollUes21/ues21/GetUserDataFromProcess";
    _url += "?pollTypeId=" + pollTypeId;
    return this._get(this.baseUrl + _url, UserDataFromProcessModel, false, headers);
  }

  postponePoll(pollTypeId: number): Observable<any> {
    const token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      "Authorization": "Basic " + token,
      "Content-Type": "application/json"
    });
    let _url = "PollUes21/Polls/Actions/postpone";
    _url += "?pollTypeId=" + pollTypeId;
    return this._put(this.baseUrl + _url, null, { headers });
  }

  getRegisterPoll(idPollType = 0, campaign = null): Observable<any> {
    let _url = "PollUes21/Polls/Actions/RegisterPoll";
    _url += '?idPollType=' + idPollType;
    _url += '&campaign=';
    if (campaign != null) {
      _url += campaign;
    }

    return this._get(this.baseUrl + _url, PollsModel, null);
  }

  validateUserPortal(pollId: number): Observable<any> {
    const token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      "Authorization": "Basic " + token,
      "Content-Type": "application/json"
    });
    let _url = "PollUes21/ues21/ValidateUserPortal";
    _url += '?pollId=' + pollId;

    return this._get(this.baseUrl + _url, ValidateUserResult, false, headers);
  }

  getAnswersAsync(idQuestion = 0, query = null): Observable<any> {
    const token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      "Authorization": "Basic " + token,
      "Content-Type": "application/json"
    });
    let _url = "PollUes21/Polls/Actions/GetAnswersAsync";
    _url += '?idQuestion=' + idQuestion;
    _url += '&query=' + query;

    return this._get(this.baseUrl + _url, PossibleAnswersModel, true, headers);
  }

  getPoll(id): Observable<any> {
    let _url = "PollUes21/Polls/Actions/Get";
    _url += '?id=' + id;

    return this._get(this.baseUrl + _url, PollsModel, null);
  }

  updatePoll(item: PollsModel) {
    const token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      "Authorization": "Basic " + token,
      "Content-Type": "application/json"
    });
    let _url = 'PollUes21/Polls/Actions/UpdatePoll';
    return this._put(this.baseUrl + _url, item, { headers }, PollStructure, false);
  }

  sendResults(req) {
    const token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      "Authorization": "Basic " + token,
      "Content-Type": "application/json"
    });
    let _url = 'PollUes21/Polls/Actions/SendResults';
    return this._put(this.baseUrl + _url, req, { headers }, FinishedPoll, false);
  }

  save(item, lastQuestion = false) {
    const token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      "Authorization": "Basic " + token,
      "Content-Type": "application/json"
    });
    let _url = 'PollUes21/Polls/Actions/SavePoll?lastQuestion=' + lastQuestion;
    return this._put(this.baseUrl + _url, item, { headers }, null, false);
  }

  pollResponses(item) {
    const token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      "Authorization": "Basic " + token,
      "Content-Type": "application/json"
    });
    let _url = 'PollUes21/Polls/Actions/PollResponses';
    return this._put(this.baseUrl + _url, item, { headers }, FinishedPoll, false);
  }

  validateToken(token) {
    let _url = 'PollUes21/Polls/Actions/validateToken';
    const headers = new HttpHeaders({
      "Authorization": token,
      "Content-Type": "application/json"
    });
    return this._post(this.baseUrl + _url, null, {headers: headers}, null, false);
  }

  getProvinces() {
    let _url = "PollUes21/GetProvincias";
    return this._get(this.baseUrl + _url, null, null);
  }

  getCities(id) {
    let _url = "PollUes21/GetLocalidades/" + id;
    return this._get(this.baseUrl + _url, null, null);
  }
}
