import { JsonObject, JsonProperty } from 'json2typescript';
import { PollsModel } from './polls.model';

@JsonObject('Roles')
export class Roles {
  @JsonProperty('id', Number, true)
  id: number = undefined;
  @JsonProperty('description', String, true)
  description: string = undefined;
  @JsonProperty('name', String, true)
  name: string = undefined;
}

@JsonObject('UserDataModel')
export class UserDataModel {
  @JsonProperty('name', String, true)
  name: string = undefined;
  @JsonProperty('lastName', String, true)
  lastName: string = undefined;
  @JsonProperty('documentNumber', Number, true)
  documentNumber: number = undefined;
  @JsonProperty('usrPortal', String, true)
  usrPortal: string = undefined;
  @JsonProperty('roles', [Object], true)
  roles: Array<Roles> = undefined;
  @JsonProperty('contactPhone', String, true)
  contactPhone: string = undefined;
  @JsonProperty('primaryEmail', String, true)
  primaryEmail: string = undefined;
  @JsonProperty('birthDate', String, true)
  birthDate: string = undefined;
}
